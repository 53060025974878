.map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: fixed;
  left: 0;
  z-index: 1;
  width: 100vw;
  .navigation {
    display: flex;
    position: absolute;
    left: 2rem;
    top: 15px;

    & > div {
      margin-right: .5rem;
    }

    .search-box {
      position: relative;
      background: #fff;
      height: 30px;
      border-radius: 40px;
      padding: 10px;

      .search-text {
        border: none;
        background: none;
        outline: none;
        float: left;
        color: #000;
        font-size: 16px;
        font-weight: normal;
        transition: 0.4s;
        line-height: 20px;
        width: 240px;
        padding: 5px 10px;

        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .search-btn {
        background: white;
        color: #000;
        float: right;
        width: 40px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s;
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        right: .5rem;
      }
    }

    .locations-container {
      background-color: #fff;
      width: 456px;
      height: 310px;
      position: absolute;
      border-radius: 4px;
      padding: 25px;
      top: 120%;
      left: 0;
      transition: 0.2s ease-in;
      visibility: visible;
      opacity: 1;
      margin-top: 3px;

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        top: 0;
        left: 2.3rem;
        position: absolute;
        background-color: #fff;
        transform: rotate(45deg) translateY(-5px);
      }

      &-close {
        position: absolute;
        transition: 0.2s ease-in;
        visibility: hidden;
        opacity: 0;
        display: none;
        width: 456px;
        height: 310px;
        top: 120%;
        left: 0;
      }

      .locations-list {
        list-style-type: none;
        padding-left: 0 !important;
        overflow-y: auto;
        margin: 0;
        height: 290px;

        &-item {
          border-bottom: 1px solid rgba(192, 195, 200, 0.5);
          padding-bottom: 10px;
          padding-top: 1px;
          padding-left: 20px !important;
          transition: 0.2s ease-in;
          cursor: pointer;
          line-height: 1.2rem;

          &:hover {
            background-color: rgba(192, 195, 200, 0.15);

            p {
              color: #3D3D3D;
              opacity: 1;
              font-weight: 500;
            }
          }

          & > p {
            font-size: 16px;
            color: #000;
            opacity: 0.5;
            margin-bottom: 1px;
          }

          & > span {
            color: #c0c3c8;
            font-size: 12px;
          }
        }
      }
    }

    .lds-ring {
      display: inline-block;
      position: relative;
      width: 51px;
      height: 51px;
      left: 43%;
      top: 38%;

      div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 35px;
        height: 35px;
        margin: 8px;
        border: 3px solid #000;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #000 transparent transparent transparent;

        &:nth-child(1) {
          animation-delay: -0.45s;
        }

        &:nth-child(2) {
          animation-delay: -0.3s;
        }

        &:nth-child(3) {
          animation-delay: -0.15s;
        }
      }
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .btn-container {
      position: relative;

      .filter-container {
        transition: 80ms ease-in;
        background-color: #fff;
        position: absolute;
        width: 737px;
        visibility: hidden;
        opacity: 0;
        top: 120%;
        left: 0;
        border-radius: 4px;
        padding: 20px 22px 60px;
        text-align: left;
        z-index: 9;

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          background-color: #fff;
          position: absolute;
          top: -2px;
          left: 15px;
          z-index: -1;
          border-radius: 2px;
          transform: rotate(45deg);
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: inline-flex;
          flex-wrap: wrap;
          height: 100%;
          width: 100%;
          justify-content: space-between;
        }
        &-title {
          font-size: 18px;
          margin: 0 10px;
          font-weight:500;
          color: #000;
        }
        &.active{
          opacity: 1;
          visibility: visible;
        }
        &-object-header,&-powerline-header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1.5px solid #DFE1E3;
          margin: 0 10px;
          padding: 15px 0 8px;
          &>.container-filter{
            margin-left: 0;
            margin-bottom: 0;
            transform: translate(0) !important;
            &>.label-text-filter-list{
              margin-left: 10px;
              color: #889198;
              font-weight: 400;
            }
          }
          p{
            margin: 0;
            padding: 0;
            color: #3FA2F7;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
          }
        }
      }

      & > .btn-filter,.btn-control{
        background-color: #fff;
      }
    }

    .btn {
      border-radius: 25px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: "Poppins", sans-serif;

      & > span {
        color: #000;
        margin-left: 10px;
      }

      &.btn-filter {
        border: none;

        &:focus-within{
            background-color: #fbc203;

          .filter-container {
            visibility: visible;
            opacity: 1;
            &-title {
              font-size: 18px;
              margin: 0 10px;
              font-weight: 500;
              color: #000;
            }
          }
        }
      }
    }

    .filter-container {

      .filter-list-item {
        width: 360px;
        font-size: 16px;
        font-weight: 500;
        .container-filter {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          img {
            width: 48px;
            height: 48px;
            margin-left: 7px !important;
            margin-right: 3px;
            margin-top: 3px !important;
          }
        }
      }
    }

  }

  .navigation-right {
    position: absolute;
    display: flex;
    align-items: center;

    .switch-container {
      display: inline-block;
      font-size: 0;
      height: 48px;
      width: max-content;
      border: 1px solid #fff;
      border-radius: 25px;
      background-color: #fff;
      margin-right: 2rem;

      & > label {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        //margin-right: 2px;
        transition: all 0.3s ease;
      }

      #car-icon {
        transform: translate(1px,4px);
      }
      #drone-icon{
        transform: translate(-1px,9px);
      }

      #car + label:before,
      #drone + label:before
      {
        content: "";
        width: 100%;
        height: 44px;
        position: absolute;
        z-index: 0;
        border-radius: 25px;
      }

      #car + label:before{
        transition: all 0.3s ease;
        background-color: #000;
        top: -6px;
        left: calc(100% - 2px);
      }

      #drone + label:before{
        top: 2px;
        right: 2px;
        border-radius: 25px;
      }

      #car:checked + label:before {
        left: 2px;
      }

      #drone:checked + label:before{
        background-color: #8737D6;
        transition-delay: 0.3s;
      }
    }
    .map_logo{
      height: 40px; 
    }
  }
}
@media only screen and (min-width: 900px) {
  .navigation-right{
    right: 2rem;
    top: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .btn-container{
    .btn-control{
      position: fixed;
      left: 2rem;
      bottom: 186px;
    }
  }
  .map-header{
    width: 0;
    height: 100%;
    .btn-report{
      position: fixed;
      left: 2rem;
      bottom: 126px;
    }
    .navigation-right{
      right: auto;
      bottom: 60px;
    }
  }
  .navigation-right{
    left: 2rem !important;
    bottom: 0;
  }
  .map-layer{
    bottom: 30px !important;
  }
  .control-container{
    width: 100vw !important;
  }
   .navigation-right{
     &>img{
      display: none;
     }
   }
  .filter-container {
    height: 420px !important;
    overflow: auto;
    width: 76vw !important;
    left: -290px !important;
    &:before{
      top: 73px !important;
      left: 335px !important;
      position: fixed !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .locations-container{
    width: 70vw !important;
  }
}

.info-container-backdrop{
  position: fixed;
  z-index: 999;
  background-color: rgba(0,0,0,.4);
  width: 100vw;
  height: 100vh;
  &>.info-container{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: transform 1s ease;
    width: 687px;
    height: 619px;
    display: flex;
    align-items: flex-start;
    &>.info-content{
      width: 599px;
      &>.content-header{
        position: relative;
        box-sizing: border-box;
        background: linear-gradient(180deg, rgba(0,75,144,1) 0%, rgba(0,75,144,0.94) 17%, rgba(0,75,144,0.8) 40%, rgba(0,75,144,0.2) 100%);
        border-radius:10px 10px 0 0;
        padding: 40px 0 20px 44px;
        color: #fff;
        &>.logo{
          padding: 20px 0;
        }
        &>img:last-child{
          border-radius:10px 10px 0 0;
          position: absolute;
          width: 599px;
          left: 0;
          top: 0;
          z-index: -1;
          object-fit: cover;
        }
        &>h1,&>h2{
          margin: 0;
          padding: 0;
          text-shadow: 0 3px 6px rgba(0,0,0,.2);
        }
        &>h1{
          font-size: 30px;
          font-weight: 500;
        }
        &>h2{
          font-size: 18px;
          font-weight: 400;
          opacity: .8;
        }
      }
      &>.content-main{
        height: 491px;
        background-color: #fff;
        border-radius:0 0 10px 10px;
        padding:30px 44px;
        overflow-y: auto;
        text-align: center;
        &>h2,&>p{
          margin: 0;
          padding: 0;
          text-align: left;
        }
        &>h2{
          margin: 40px 0 16px;
          font-weight: 600;
          font-size:22px;
        }
        &>p{
          font-size: 15px;
          font-weight: 400;
        }
        &>img{
          width: 100%;
          margin-left: -5px;
          margin-top: 20px;
        }
        &>.map-integrations{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 32px;
        }
        &>.video{
          appearance: none;
          border-radius:22px;
          width: 160px;
          height: 44px;
          color: #fff;
          outline: none;
          border: none;
          font-size: 16px;
          font-weight: 400;
          background-color: #007AFE;
          margin: 62px 0 14px;
          cursor: pointer;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          span{
            margin-left: 3px;
            font-size: 16px;
            font-weight: 400;
            font-family: Poppins, sans-serif;
          }
        }
        &>.video-text{
          width: 270px;
          margin: 0 auto 38px;
          color: #000;
        }


        &>.footer{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top:.5px solid #9E9E9E;
          padding-top:16px;
          &>span{
            font-size: 14px;
            font-weight: 400;
            color: #9E9E9E;
          }
          &>span:last-of-type{
            border-right:.5px solid #9E9E9E;
            padding-right: 8px;
          }
          &>img{
            margin-top: -4px;
            height: 13px;
          }
        }
      }
    }
    &>.close-btn{
      appearance: none;
      border: none;
      background-color: transparent;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-left: 8px;
      cursor: pointer;
    }
    @keyframes transform {

      0%{
        transform: translate(-50%,-50%) scale(.6);
      }
      100%{
         transform: translate(-50%,-50%);
       }
    }
  }
}
@media only screen and (max-width: 768px) {
  .info-container{
    width: 100% !important;
    height: 100% !important;
    .info-content{
      width: 100% !important;
      .content-header{
        padding: 20px !important;
        border-radius: 0 !important;
        h1{
          font-size: 20px !important;
        }
        &>img:last-child{
          width: 100% !important;
        }
      }
      .content-main{
        padding: 20px !important;
        .map-integrations{
          img{
            height: 15px;
          }
        }
        .footer{
          span{
            font-size: 10px !important;
          }
          img{
            margin-left: 8px;
          }
        }
      }
    }
    .close-btn{
      position: fixed;
      right: 20px;
      top: 20px;
    }
  }
}


.message-container{
  position: fixed;
  left:50%;
  bottom:20px;
  transform: translateX(-50%);
  z-index: 9999;
  &>.message-content{
    display: flex;
    width: max-content;
    background-color: rgba(0,0,0,.8);
    color: #fff;
    padding: 19px 33px;
    border-radius: 4px;
    &>div:first-child{
      margin-top: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-right: 11px;
    }
    &>div.duration{
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      background-color: #FBC233;
      animation: duration 3s ease;
    }
    @keyframes duration {
      0%{
        width: 100%;
      }
      100%{
        width: 0;
      }
    }
  }
}


@media only screen and (max-width:900px) {
  .detail-container{
    scale: .8;
    left: 0 !important;
  }  
}

.detail-container {
  position: fixed;
  z-index: 99;
  left: 30px;
  bottom: 40px;
  width: 375px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -ms-scroll-snap-points-y: revert;
}

.map-layer {
  position: fixed;
  bottom: 18px;
  right: 20px;
  cursor: pointer;
  object-fit: cover;
  &>img{
    width: 77px;
    height: 68px;
    border-radius: 8px;
  }
}

.report-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: #F9F9FA;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  overflow: auto;

  .report-modal-container {
    padding: 30px;
  }

  &:focus-visible {
    outline: none;
  }

  .report-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: #666;

    .back-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  &-header {
    padding: 10px;
    display: flex;

    &-title {
      flex: 1;

      .title {
        font-size: 30px;
        margin: 0;
      }

      .subtitle {
        font-size: 18px;
        color: #9E9E9E;
        margin: 0;
      }
    }

    &-side {
      flex: 1;

      &-items {
        display: flex;
        justify-content: flex-end;

        .road-info {
          padding-right: 30px;

          .title {
            font-size: 16px;
            color: #9E9E9E;
            margin-top: 0;
            margin-bottom: 0;
          }

          .road-info-value {
            font-size: 31px;

            img {
              width: 24px;
              height: 24px;
              margin-right: .5rem;
            }
          }
        }

        .view-type {
          display: flex;
          align-items: center;
          padding-left: 15px;
          padding-right: 30px;

          & > div {
            margin-right: .4rem;
          }
        }

        .export-btn {
          cursor: pointer;

          p {
            display: flex;
            align-items: center;
            background-color: #007AFE;
            color: #fff;
            padding: 8px 25px;
            border-radius: 22px;

            img {
              margin-right: .3rem;
            }
          }
        }
      }
    }
  }

  &-slider {
    margin: 0 10px;
    padding-top: 15px;

    &-items {
      cursor: move;
      padding: 5px 2px;

      &::selection, & *::selection {
        background-color: transparent;
      }
    }

    &-item {
      display: flex;
      margin-right: 10px;
      height: calc(100% - 2px);
      background-color: #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
      border-radius: 8px;

      .image-container {
        display: flex;
        align-items: center;
        padding: 0 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          color: #9E9E9E;
          margin: 0;
          padding-top: 10px;
        }

        .subtitle {
          color: #000000;
          font-size: 32px;
          margin: 0;
          padding-bottom: 10px;
        }
      }
    }
  }

  &-detail {
    display: flex;
    background-color: #fff;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    flex-wrap: wrap;

    &-filter {
      width: 50%;

      &-title {
        font-size: 22px;
        font-weight: 500;
        margin-top: 10px;
        margin-left: 10px;
      }

      &-description {
        color: #9E9E9E;
        font-weight: 300;
        margin-left: 10px;
      }

      &-inputs {
        margin-top: 20px;

        .container-filter {
          font-size: 16px;
          font-weight: 500;

          .label-text-filter-list {
            margin-left: 1.5rem;
          }
        }

        .check-reset {
          color: #0A70DD;
          font-size: 16px;
          text-decoration: underline;
          margin-left: 10px;
          margin-top: 1.5rem;
          cursor: pointer;
        }
      }
    }

    &-chart {
      width: 50%;
    }
  }
}

.report-detail-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #F9F9FA;
  overflow: auto;

  &:focus-visible {
    outline: none;
  }

  &>.reportDetailHeader{
    display: flex;
    align-items: center;
    padding: 40px;
    & > button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .report-detail-modal-wrapper {
    padding:30px 14%;

    .report-detail-modal-header {
      margin: 20px;
      display: flex;
      justify-content: space-between;

      .logo img {
        width: 100%
      }

      .report-detail-modal-date {
        display: flex;
        flex-direction: column;

        time {
          text-align: right;
          font-size: 18px;

          &:first-child {
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }

    .report-detail-modal-content {

      &-title {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
      }

      &-subtitle {
        text-align: center;
        font-size: 18px;
        color: #9E9E9E;
      }

      table {
        width: 100%;
        border-spacing: 0;
        margin-top: 2rem;

        thead {
          background-color: #ccc;
          display: table-row-group;

          tr {
            th {
              padding: 10px;
              font-size: 16px;
              font-weight: 500; 
              color: #000;
              text-align: left;
              white-space: nowrap;
              span{
                font-weight: 400;
              }
            }
          }
        }

        tbody {
          tr {

            td {
              border-collapse: collapse;
              border-bottom: 1px solid rgba(192, 195, 200, .5);

              padding: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #000;
              text-align: left;

              &:first-child {
                font-weight: 500;
              }
            }
          }
        }
      }

      &-footer {
        &-info {
          background-color: #EEEEF0;
          display: flex;
          justify-content: space-between;
          padding: 20px;
          font-size: 15px;
          margin: 4rem 0 2rem 0;

          .light {
            font-weight: 300;
          }
          &-right{
            display: flex;
            align-items: center;
            &>img{
              margin-left:20px;
              height: 23px;
            }
          }
        }
      }

      .report-loading{
        position: fixed;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        text-align: center;
        & > img{
          margin-top: 37px;
          -webkit-animation: spin 3s linear infinite;
          @-moz-keyframes spin {
            100% { -moz-transform: rotate(360deg); }
          }
          @-webkit-keyframes spin {
            100% { -webkit-transform: rotate(360deg); }
          }
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform:rotate(360deg);
            }
          }
        }
        &>.report-created {
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          margin-top: 2rem;

          &:after {
            animation: dots 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            content: ""
          }

          @keyframes dots {
            0% {
              content: ".";
            }
            50% {
              content: "..";
            }
            100% {
              content: "...";
            }
          }
        }
      }

    }
  }
  .report-detail-modal-content-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;

    @media print {
      display: none;
    }

    &-export-pdf,
    &-export-excel,
    &-export-print {
      button, a {
        width: 150px;
        height: 44px;
        margin: 0 .3rem;
        border-radius: 22px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        img {
          margin-right: .3rem;
        }

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  &>.right-export-menu{
    display: flex;
    flex-direction: column;
    position: fixed;
    opacity: 1;
    visibility: visible;
    bottom: 80px;
    right: 50px;
    transition: all 300ms ease;

    &>.btn{
      width: 55px;
      height: 55px;
      background-color: #2E7D32;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &>img{
        width: 28px;
      }
    }
    &.non-visible{
      opacity: 0;
      visibility: hidden;
    }
  }
}
@media only screen and (max-width:768px){
  .right-export-menu{
    display: none !important;
  }
}

.container-filter {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
  margin-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: flex-start;
  transform: translateY(10px);

  .label-text-filter,
  .label-text-filter-list {
    white-space: nowrap;
    color: #000;
    font-weight: 500;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark-filter {
    height: 19px;
    width: 19px;
    background-color: transparent;
    border: 1px solid #c0c3c8;
    border-radius: 3px;

    &:after {
      content: "";
      display: none;
      width: 4px;
      height: 8px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg) translate(7px, -3px);
      -ms-transform: rotate(45deg) translate(7px, -3px);
      transform: rotate(45deg) translate(7px, -3px);
    }
  }

  input:checked ~ .checkmark-filter:after {
    display: block;
  }
  input:checked ~ .checkmark-filter{
    border:1px solid rgb(251,194,51);
  }
  .powerline-icon{
    position: relative; 
    box-sizing: border-box;
    width: 38px;
    height: 6px;
    margin: 0 7px 0 11px;
    border-radius: 10px;
    &::after{
      content: '';
      width: 30px;
      height: 2px;
      position: absolute;
      top: 2px;
      left: 4px;
      background-color:#000;
      opacity: .2;
    }
  }
}

.control-container{
  box-sizing: border-box;
  position: absolute;
  top: -15px;
  left: -2rem;
  height: 100vh;
  width: 597px;
  background-color: #fff;
  padding: 0 30px;
  transition: transform 0.3s ease;
  overflow-y: auto;
  z-index: 9;
  & > .close-btn{
    appearance: none;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 27px;
    height: 27px;
    padding: 0;
    background-color: #C0C3C8;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > h2,
  & > h3{
    margin: 0;
    padding: 0;
  }
  & > h2.title{
    font-size: 26px;
    font-weight: 500;
    margin-top: 50px;
  }
  & > h3.subtitle{
    color: #9E9E9E;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 18px;
  }
  & > label {
    position: relative;
    & > input.control-system {
      box-sizing: border-box;
      appearance: none;
      width: 371px;
      height: 50px;
      padding: 0 22px;
      margin-bottom: 12px;
      border: none;
      border-radius: 25px;
      box-shadow: 0 0.5px 2px rgba(0, 0, 0, .2);
      outline: none;
      opacity: .5;
      font-size: 18px;

      &::placeholder {
        font-size: 16px;
        color: #3D3D3D;
      }
    }

    & > img {
      position: absolute;
      right: 27px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  & > table.control-data-table{
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    &>tr:first-of-type{
      background-color: #fff;
      position: sticky;
      top: 0;
    }
    & > tr{
      font-size: 16px;
      transition: background-color .3s ease;
      &:not(:first-child):hover{
        background-color: rgba(192,195,200,.15);
        cursor: pointer;
      }
      & > td:first-child{
        font-weight: 500;
      }
      & > td{
        padding: 12px;
        border-bottom: 1px solid rgba(192,195,200,.5);
        & > div{
          display: flex;
          & > div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: #EDEEEF;
            text-align: center;
            color: #a4a4a4;
            &:not(:first-of-type){
              border: 3px solid #fff;
              margin-left: -15px;
              margin-top: -3px;
            }
            &>img{
              border-radius: 50%;
              //opacity: .4;
              width: 52px;
              height: 52px;
              object-fit: cover;
            }
          }
        }
      }
      & > th{
        padding: 26px 13px 6px;
        font-weight: 500;
        border-bottom: 2px solid rgba(192,195,200,.5);
        user-select: none;
        &>.rotateImg{
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  body * {
    visibility: hidden;
  }

  .report-detail-modal-container {
    margin: 0;

    * {
      visibility: visible;
      max-width: 100%;
    }

    .report-detail-modal-wrapper {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 768px){
  .report-modal-container{
    padding: 10px !important;
    .report-modal-content-header-title{
      display: none;
    }
    .road-info-value{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
    .export-btn{
      position: fixed;
      top: -5px;
      right: 10px;
    }
    .report-modal-content-detail-filter{
      width: 100%;
    }
    .report-modal-content-detail-chart{
      width: 100%;
    }
  }
  .report-modal-content-header-side-items{
    justify-content: flex-start ;
  }
  .message-container{
    .message-content{
      font-size: 14px;
      padding: 12px;
      &>div:first-child{
        margin-top: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .report-detail-modal-container{
    .reportDetailHeader{
      padding: 20px;
    }
    .report-detail-modal-wrapper{
      padding: 20px;
    }
    .logo>img{
      margin-top: 5px;
      width: 70% !important;
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  padding: 0;
  margin: 0;
  max-height: 100vh;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #3d3d3d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 0;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

#logo {
  position: absolute;
  bottom: 11px;
  width: 115px;
  left: 25px;
  margin: auto;
}

.mapboxgl-ctrl-top-right {
  top: unset !important;
  /* bottom: 30px !important; */
  height: 100vh !important;
  right: 10px !important;
}

@media only screen and (max-width: 768px) {
  .mapboxgl-ctrl-top-right {
    bottom: 45px !important;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact {
    right: 13px !important;
  }
}

.controller-group {
  position: absolute;
  top: unset;
  right: 10px;
  bottom: 30px;
}

.controller-group > div {
  position: sticky !important;
  margin-right: 10px;
  margin-top: 10px;
}

.map-container {
  width: 100vw;
  height: 100vh;
}

.MuiAutocomplete-popper {
  z-index: 99999999 !important;
}

.scrollbar {
  scrollbar-color: rgba(255, 255, 255, 0.4);
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}

.avatarSkeletonContainer {
  height: 0;
  overflow: hidden;
  position: relative;
}

.avatarLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 15px;
  border: 3px solid #ffffff;
}
*::-webkit-scrollbar-track {
  background: #ffffff;
  border: 3px solid #ffffff;
}

*::-webkit-scrollbar {
  width: 13px;
  border: 3px solid #ffffff;
}

.MuiAutocomplete-popper {
  width: 250px !important;
}

.MuiOutlinedInput-notchedOutline {
  /* border-color: rgba(234, 236, 242, 0.3) !important; */
  /* border-width: 2px !important; */
}

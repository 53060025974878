.mapboxgl-ctrl-top-right {
  display: flex;
  z-index: 0 !important;
}
.mapboxgl-ctrl-top-right > .mapboxgl-ctrl{
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 110px;
}

.mapboxgl-ctrl-group {
  background-color: transparent !important;
}

.mapboxgl-ctrl-top-right > .mapboxgl-ctrl > button {
  width: 36px !important;
  height: 36px !important;
  background-color: #fff;
}

.mapboxgl-ctrl-top-right > .mapboxgl-ctrl > .mapboxgl-ctrl-compass {
  margin-top: 50px;
  border-radius: 50%;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none !important;
}

.mapboxgl-ctrl-zoom-out {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: translateY(40px);
}

.mapboxgl-ctrl-zoom-in {
  transform: translateY(40px);
}

.mapboxgl-popup-content {
  width: 300px;
  box-shadow: 0px 8px 8px #455b6314;
  font-family: 'Poppins' ,sans-serif;
  padding-bottom: 10px;
}
.mapboxgl-popup{
  max-width: 300px !important;
}
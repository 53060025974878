@font-face {
  font-family: 'Manrope';
  src: url("https://fonts.googleapis.com/css?family=Manrope");
}

.auth-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  & > div {
    width: 100%;
    position: relative;
  }

  & > .left {
    & > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 0;
    }

    & > .auth-info {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 35px 40px;
      background-color: rgba(0, 0, 0, .3);
      border-radius: 20px;
      color: white;
      img{
        width: 200px;
        object-fit: cover;
      }

      h1 {
        margin: 0;
        padding: 0;
        font-size: 30px;
        font-weight: 500;
        width: max-content;
        text-shadow: 0 3px 6px rgba(0,0,0,.4);
      }

      h3 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 400;
        opacity: .8;
        text-shadow: 0 3px 6px rgba(0,0,0,.4);
      }

      p.info {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
        margin: 0;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  & > .right {
    overflow: auto;
    font-family: 'Manrope',sans-serif;
    .center {
      padding: 20% 15%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .auth-layout {
    display: block;
    .left{
      .auth-info{
        width: 60%;
        img{
          width: 140px;
        }
        h1{
          font-size: 22px;
          line-height: 24px;
          margin: 10px 0;
          width: auto;
        }
        h3{
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    > .right > .center {
      padding: 50px 20px;
    }
  }
  .auth-layout > div:first-child {
    height: 50%;
    width: 100%;
  }
}
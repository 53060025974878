.auth {
  h1 {
    margin: 0 0 16px;
    padding: 0;
    font-weight: 700;
    font-size: 35px;
    color: #253745;
  }

  > p {
    margin: 0 0 37px;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    color: #737A80;
  }

  form {
    position: relative;

    span.forgot {
      position: absolute;
      right: 0;
      color: #0074E9;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
    }

    label.form-input {
      display: flex;
      flex-direction: column;
      margin: 20px 0;

      >span {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        color: rgba(0,0,0,.5);
        margin-bottom: 10px;
      }

      input {
        appearance: none;
        border: 1px solid rgba(192,195,200,.5);
        border-radius: 25px;
        height: 50px;
        padding: 0 25px;
        font-size: 18px;
        font-weight: 400;
      }

      input:focus {
        outline: none;
      }
    }

    label.checkbox {
      display: flex;
      align-items: center;
      margin: 5px 25px 20px;
      input {
        appearance: none;
        border: 1px solid #C0C3C8;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        &:checked{
          position: relative;
          background-color: #FBC233;
          border: none;
          &:after{
            content: '\2713';
            position: absolute;
            color:#000;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 12px;
            font-weight: bold;
            z-index: 9;
          }
        }
      }

      span {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 300;
        color: #737A80;
      }
    }

    button {
      appearance: none;
      width: 100%;
      height: 50px;
      border: none;
      background-color: #C0C3C8;
      color: rgba(0,0,0,.5);
      border-radius: 25px;
      font-size: 18px;
      font-weight: 500;
      font-family: 'Manrope',sans-serif;
      &:not(:disabled){
        cursor: pointer;
      }
    }

    p {
      font-size: 16px;
      margin-left: 25px;
      color: #737A80;
      span {
        color: #000;
        margin-left: 5px;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
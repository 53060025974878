@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "Poppins", sans-serif;
}

html {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#zoomIn {
  display: none !important;
}

#zoomOut {
  display: none !important;
}

.mapboxgl-popup-content > div > img {
  display: block !important;
  border-radius: 4px;
  height: 150px !important;
}

.mapboxgl-popup-content > div {
  display: flex !important;
}


.btn {
  cursor: pointer;

  &.primary {
    background-color: #007AFE;
    color: #fff;
  }

  &.danger {
    background-color: #FF3717;
    color: #fff;
  }

  &.success {
    background-color: #2E7D32;
    color: #fff;
  }

  &.light {
    background-color: #fff;
    color: #000;
  }

  &.light-outline {
    background-color: transparent;
    border: 1px solid #000 !important;
  }
}

.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  border-radius: 2px;

  &-danger {
    background-color: #F45063;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 200;
    white-space: nowrap;
  }

  span.close-icon {
    cursor: pointer;
    color: rgba(0,0,0,.4);
    margin-left: 0.3rem;
    font-size: 1.3rem;
  }
}
